<template>
  <v-container fluid class="content-wrapper mb-6">
    <!-- <div class="overflow-y-auto d-flex flex-column pa-3"> -->
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600">{{ $t("SigninRoomDetails.title") }}</h4>
      <v-btn icon class="ml-3" @click="initRoomDetails()" :class="loadingRoomData && 'animate__spining'" :disabled="loadingRoomData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <v-row row wrap>
      <v-col cols="12">
        <div class="card card-shadow pa-5">
          <!-- <h3 class="title-md">{{ $t("SigninRoomDetails.title") }}</h3> -->
          <v-row row wrap>
            <v-col
              v-if="loadingRoomData"
              cols="12"
              align="end"
              class="d-flex flex-row align-center justify-end"
            >
              <v-skeleton-loader type="button"></v-skeleton-loader>
            </v-col>
            <v-col v-else cols="12" align="end">
              <v-btn
                :loading="loadingaddnew"
                :disabled="loadingaddnew"
                color="#666ee8"
                class="white--text"
                @click="addnewFunc"
                depressed
              >
                <v-icon left dark>mdi-plus-circle-outline</v-icon>
                <span class="textstyle">
                  {{ $t("SigninRoomDetails.addnew") }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row row wrap>
            <template v-if="loadingRoomData" v-for="n in 3">
              <v-col
                :key="n"
                cols="12"
                sm="12"
                md="6"
                lg="4"
                class="d-flex flex-column align-left justify-center"
              >
                <v-skeleton-loader type="image, article, actions"></v-skeleton-loader>
              </v-col>
            </template>
            <template v-if="!loadingRoomData" v-for="item in itemRooms">
              <v-col
                :key="item._id"
                cols="12"
                sm="12"
                md="6"
                lg="4"
                class="d-flex flex-column align-left justify-center"
              >
                <img
                  :src="
                    item.img
                      ? item.img
                      : require('@/assets/img/thumb/placeholder-image.png')
                  "
                  alt
                  class="roomdetails-img"
                />
                <span class="mt-3 roomdetails-name">{{ item.name }}</span>
                <span class="roomdetails-detail">
                  {{
                    `${$t("SigninRoomDetails.roomdetails")} ${$t(
                      "SigninRoomDetails.roomdetails_adult"
                    )} ${item.normal} ${$t("SigninRoomDetails.roomdetails_adult_unit")}`
                  }}
                  <span v-if="item.child && item.max > item.normal">
                    {{
                      `${" + " + $t("SigninRoomDetails.roomdetails_child")} ${
                        item.max - item.normal
                      } ${$t("SigninRoomDetails.roomdetails_child_unit")}`
                    }}
                  </span>
                  <span>
                    {{
                      ` (${$t("SigninRoomDetails.roomdetails_max")} ${item.max} ${$t(
                        "SigninRoomDetails.roomdetails_max_unit"
                      )})`
                    }}
                  </span>
                </span>

                <v-row row wrap class="ma-0 mt-3" style="overflow: auto">
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    :class="$vuetify.breakpoint.mobile ? '' : 'pa-0'"
                    class="d-flex align-end"
                  >
                    <v-btn
                      block
                      depressed
                      dark
                      color="#666ee8"
                      @click="editdataFunc(item)"
                      style="min-height: 2.5rem !important"
                    >
                      <label class="textstyle btn-room-details-text">
                        {{ $t("SigninRoomDetails.edit") }}
                      </label>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    :class="$vuetify.breakpoint.mobile ? '' : 'py-0'"
                    class="d-flex align-end"
                  >
                    <v-btn
                      block
                      depressed
                      dark
                      color="#ff4961"
                      :disabled="itemRooms.length == 1"
                      @click="removedataFunc(item)"
                      style="min-height: 2.5rem !important"
                    >
                      <label class="textstyle btn-room-details-text">
                        {{ $t("SigninRoomDetails.delete") }}
                      </label>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    :class="$vuetify.breakpoint.mobile ? '' : 'pa-0'"
                    class="d-flex align-end"
                  >
                    <div style="width: 100%">
                      <input
                        type="file"
                        :name="item._id"
                        :id="item._id"
                        class="imagefile"
                        @change="handleFileChange(...arguments, item)"
                      />
                      <label :for="item._id" class="textstyle btn-room-details-text">
                        <!-- <v-icon class="mr-3" color="white">mdi-image</v-icon> -->
                        {{ $t("SigninRoomDetails.uploadphoto") }}
                      </label>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- </div> -->
    <v-dialog v-model="dialogConfirmDelete" persistent max-width="360">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >{{ $t("Alert.doyouwanttodelete") }}</v-card-title
        >
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            @click="confirmRemove(propDelete)"
            depressed
            color="#e83e8c"
            class="pa-3 mr-3 white--text"
            >{{ $t("SigninGeneral.btn_del_property") }}</v-btn
          >
          <v-btn
            @click="dialogConfirmDelete = false"
            color="#e83e8c"
            class="pa-3"
            outlined
            >{{ $t("SigninGeneral.btn_cancel") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninRoomDetailsScreen",
  metaInfo: {
    title: "Room Details",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    itemRooms: [],
    userToken: "",
    propertyID: "",
    loadingaddnew: false,
    fileupload: null,
    dialogConfirmDelete: false,
    loadingRoomData: false,
    propDelete: {},
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initRoomDetails();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initRoomDetails);
    // this.colorHover();
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      // console.log('ttt', document.querySelector('.hover_btn'))
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    async initRoomDetails() {
      const self = this;
      self.fileupload = null;
      self.itemRooms = [];
      self.loadingRoomData = true;
      EventBus.$emit("loadingtillend");
      var temp = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log("initRoomDetails: ", res);
          EventBus.$emit("endloading");
          if (res?.data?.result[0]?.rooms?.length) {
            res?.data?.result[0]?.rooms?.map((el) => {
              temp.push({
                _id: el._id,
                img: el?.cover_picture
                  ? `${process.env.VUE_APP_PIC_API}/${el?.cover_picture}`
                  : null,
                name: el?.room_name?.value || "",
                child: res?.data?.result[0]?.child,
                max: el?.customer?.max || 0,
                normal: el?.customer?.normal || 0,
                item: el,
              });
            });
          }
        } else {
          EventBus.$emit("endloading");
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemRooms = temp;
        self.loadingRoomData = false;
        // console.log(self.itemRooms);
      }
    },
    addnewFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const item = {
        name: "SigninRoomDetailsCreateScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      self.loadingaddnew = true;
      setTimeout(() => {
        EventBus.$emit("changePathname", item);
        EventBus.$emit("endloading");
        self.loadingaddnew = false;
      }, 1500);
    },
    editdataFunc(item) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const link = {
        name: "SigninRoomDetailsUpdateScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
          roomId: item._id,
        },
      };
      self.loadingaddnew = true;
      EventBus.$emit("changePathname", link);
      setTimeout(() => {
        EventBus.$emit("endloading");
        self.loadingaddnew = false;
      }, 1500);
    },
    removedataFunc(item) {
      const self = this;
      self.dialogConfirmDelete = true;
      self.propDelete = item;
    },
    async confirmRemove(item) {
      const self = this;
      self.dialogConfirmDelete = false;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.delete(
          process.env.VUE_APP_API + `/property/room?room_id=${item._id}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.delete_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
        // console.log("confirmDele: ", res);
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
        self.initRoomDetails();
      }
    },
    handleFileChange(file, item) {
      const self = this;
      // console.log(file, typeof file, item)
      if (file !== "undefined") {
        self.updatePictures(file.target.files[0], item._id);
      }
    },
    async updatePictures(file, key) {
      const self = this;
      var formData = new FormData();
      formData.append(`${key}`, file);
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API +
            `/property/picture/cover?property_id=${self.propertyID}`,
          formData,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        // console.log(res)
        if (res.status == 200) {
          // console.log(res?.data);
          EventBus.$emit("endloading");
          self.initRoomDetails();
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        self.initRoomDetails();
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/roomdetails.css";
@import "../../../../styles/signin/setting.css";
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/signin/promotion.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}
</style>

<style lang="scss" scoped>
.btn-room-details-text {
  font-size: 0.75rem !important;
}

.imagefile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.imagefile + label {
  font-size: 0.85em;
  // font-weight: 700;
  color: #fff;
  // border: 1px solid #1e9ff2 !important;
  background-color: #1e9ff2;
  display: inline-block;
  padding: 0.75rem 1rem !important;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 40px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.imagefile:focus + label,
.imagefile + label:hover {
  color: #3a44e1;
  border: 1px solid #3a44e1 !important;
}
.imagefile + label {
  cursor: pointer; /* "hand" cursor */
}
.imagefile + label * {
  pointer-events: none;
}
.imagefile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.imagefile:focus + label,
.imagefile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.js .imagefile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.no-js .imagefile + label {
  display: none;
}

label {
  display: inline-block;
  margin-bottom: 0 !important;
}
</style>
